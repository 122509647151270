import { Link, NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react'
import logout from '../../services/logout/logout';
import {useDispatch, useSelector} from 'react-redux';

export default function Header(props) {

  const store = useSelector(state => state.reducer)
  let emptyurl_header="#"
  const location = useLocation()
  const hour = new Date().getHours()
  const [headerBorder, setHeaderBorder] = useState("")
  const [userGreeting, setUserGreeting] = useState('')
  const dispatch = useDispatch()

  const getUserGreeting = () => {
    if(hour < 12) setUserGreeting('בוקר טוב')
    else if(hour < 18) setUserGreeting('צוהריים טובים')
    else setUserGreeting('ערב טוב')
  }

  const headerBorderHandler = () => {
    if(location.pathname.includes("/account")){
      setHeaderBorder("none")
    }else{
      setHeaderBorder(`3px solid ${props.partner.partnerConfig.leadingColor}`)
    }
  }

  useEffect(() => {
    headerBorderHandler()
  }, [location.pathname])

  let finalMount = useRef(true)
  useEffect(() => {
    if(finalMount.current){
      finalMount.current = false
      getUserGreeting()
      props.getUserDetails()
    }
  }, [])

  return (
    <>
      <header id="header" style={{borderBottom: headerBorder}}>
        <div className="header-container"> 
          <a href={emptyurl_header} className="toggle-menu-btn" role="button" aria-expanded="false" aria-label="לפתיחת תפריט ראשי">
            <span className="menu-icon"></span>
          </a>


          <div className="header__name-block without-avatar">
            {/* <!-- if no avatar, block avatar shouldn't be displayed and should be added className without-avatar to header__name-block block --> */}
            {/* <div className="avatar"> 
              <img src="images/avatar.png" alt="" width="49" height="49"/> 
            </div> */}
            <div className="text-holder"> 
              <span>{userGreeting}</span> {props.userInfo.firstName && <strong className='user_name_title'>{props.userInfo.firstName + ' ' + props.userInfo.lastName}</strong>} <span onClick={ () => logout(props.partner.partnerLink, dispatch, true)} className='logout_link'>(להתנתקות) </span>
            </div>
          </div>


          <div className="header-collapse-backdrop">
            <div className="header-collapse"> 
              <a href={emptyurl_header} className="menu-close-btn" role="button" aria-expanded="false" aria-label="סגור חלונית תתי תפריט"></a>

              {/* header items from main partner json */}
              <ul className="header-nav">
                  {store.headerMainLink && <li><NavLink to={`${props.partner.partnerLink}/`} className='main_page_link'>ראשי</NavLink></li>}
                  {   
                      props.partner.partnerConfig.headerMenu?.map(function(item, i){
                          return(
                            <li key={i}>
                              <NavLink to={`${props.partner.partnerLink}/${item.link}`} className=''>{item.title}</NavLink>
                            </li>
                          )
                      })
                  }
              </ul> 
            </div>
          </div> 

          
          <strong className="client-logo"> 
            <Link to="/"> 
              <img className="header_logo" src={props.partner.partnerConfig?.logoUrl} alt="home_link" width="166" height="28" /> 
            </Link> 
          </strong>


        </div>
      </header>
    </>
  )
}
