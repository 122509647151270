import React from 'react';
import { DataGrid, heIL } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import {BrowserView, MobileView} from 'react-device-detect';
import AccordionBlockDashboard from '../accordion-block-dashboard/AccordionBlockDashboard';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export default function DashboardEmployeeTable(){
    const [locale] = React.useState('heIL');
    const theme = useTheme();
    theme.typography = {
        font: "14px 'AlmoniDL'"
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const columns = [
        {
            field: 'employeeName',
            headerName: 'שם עובד',
            flex: 1,
            headerClassName: 'info-label',
            sortable: true
        },
        { 
            field: 'employeeId', 
            headerName: 'מזהה עובד', 
            flex: 1, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'projectName', 
            headerName: 'שם הפרוייקט', 
            headerClassName: 'info-label',
            flex: 1, 
            overflow: 'hidden'
        },
        {
            field: 'date',
            headerName: 'תאריך',
            flex: 1, 
            headerClassName: 'info-label',
        },
        {
            field: 'numOfHoursPoints',
            headerName: 'מס׳ שעות מזכות',
            flex: 1,
            headerClassName: 'info-label',
            
        },
        { 
            field: 'actualHours', 
            headerName: 'שעות בפועל', 
            headerClassName: 'info-label',
            flex: 1,       
        },
        { 
            field: 'status', 
            headerName: 'סטטוס',
            cellClassName: 'info-label',
            flex: 1,       
        },
        { 
            field: 'dateRegister', 
            headerName: 'תאריך הרשמה', 
            headerClassName: 'info-label',
            flex: 1,       
        }
    ];
    
    const rows = [
        { 
            id:1,
            employeeName:'שמעון בוחבוט',
            employeeId: 12,
            projectName: 'ruah tova hebrew language',
            date: '12-08-03',
            numOfHoursPoints: 1,
            actualHours:2,
            status: 'בוצע',
            
            dateRegister: '01-08-2022'
        },
        { 
            id:2,
            employeeName:'רפי ימין',
            employeeId: 12,
            projectName: 'עבודה חקלאית בחווה בפתח תקווה',
            date: '15.02.22',
            numOfHoursPoints: 1,
            actualHours:2,
            status: 'לא הופיע',
            dateRegister: '01-08-2022'
        }
    ];
    return (
        <>
            <BrowserView>
                <ThemeProvider theme={themeWithLocale}>
                    <DataGrid
                        autoHeight {...rows}
                        sx={{
                            backgroundColor: '#fff', marginBottom: '20px', border: 'unset',
                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none'
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderDraggableContainer': {
                                '& .MuiDataGrid-menuIcon': {
                                    display: 'none'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    visibility: 'visible',
                                    width: '0 !important'
                                  }
                            },
                            '& .MuiTablePagination-actions': {
                                display: 'none'
                            },
                            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus' : {
                                outline: 'unset',
                            }
                        }}
                        rows={rows}
                        columns={columns}
                        getCellClassName={(params) => {
                            if (params.field === 'status' && params.value === 'בוצע') {
                              return 'green-status';
                            }
                            return 'info-label';
                          }}
                        pageSize={rows.length}
                        rowsPerPageOptions={[rows.length]}
                        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            ColumnUnsortedIcon: () => <UnfoldMoreIcon />,
                            ColumnSortedAscendingIcon: () => <FontAwesomeIcon icon={faAngleUp} />,
                            ColumnSortedDescendingIcon: () => <FontAwesomeIcon icon={faAngleDown} />
                            
                          }}
                        disableSelectionOnClick
                    />
                </ThemeProvider>
            </BrowserView>

            <MobileView>
                {
                    rows?.map(function (item, i) {
                        return <AccordionBlockDashboard key={i} data={item} />
                    })
                }
            </MobileView>
        
        </>
    )

}