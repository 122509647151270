import React, { useEffect, useState } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2';
import { AppBar, Box, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { he } from 'date-fns/locale'
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import './DashboardDialog.css'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement} from 'chart.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DashboardDatePicker from '../dashboard-datepicker/DashboardDatePicker';
import Loader from '../loader/Loader';
import { useSelector } from 'react-redux';


export default function DashboardDialog(props) {
    const store = useSelector(state => state.reducer)
    const [dataBar, setDataBar] = useState(false)
    const [dataDonut, setDataDonut] = useState(false)
    const [optionsBar, setOptionsBar] = useState(false)
    const [donutOptions, setDonutOptions] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const popupData = props.popupData

    const getPopUpInfo = async () => {
        let data = []
        let labelsData = []
        if(popupData.graphData){
            let yLabel = ""
            if(popupData.graphData && popupData.graphData.dataset){ 
                yLabel = popupData.graphData.dataset[0].label 

                popupData.graphData && popupData.graphData.dataset && popupData.graphData.dataset[0].data.map((item, index) => {
                    data.push(item.value)
                    labelsData.push(popupData.graphData.ticks[index].value)
                })
    
                setDataBar({
                    labels: labelsData,
                    datasets: [
                        {
                            label: '',
                            data: data,
                            font: {
                                family: 'AlmoniDL'
                            },
                            backgroundColor: [props.selectedLabel.mainColor, props.selectedLabel.uniqueColor, props.selectedLabel.mainColor,props.selectedLabel.uniqueColor],
                        }
                    ],
                })
    
                setOptionsBar({
                    responsive: true,
                    plugins: {title: {display: true, text: yLabel.split(' '), align: 'start', font: {family: 'AlmoniDL'}},legend:{display: false}},
                    scales: {
                        x: {
                            ticks: {maxRotation: 50, color: 'black', backdropPadding: 3, font: {family: 'AlmoniDL', size: 10}, content: "/54",display: 'absolute'},
                            grid: {display: false},
                        },
                        y: {
                            ticks: {font: {family: 'AlmoniDL'}}, grid: {display: true}
                        }
                    }
                })

                setShowPopup(true)
            }else{
                console.log('no dataset')
                setShowPopup(false)
            }
            

            //setPopupLoader(false)
        }else if(popupData.donutData){
            let data = []
            let labels = []
            let colorArray = []

            popupData.donutData && popupData.donutData.dataset && popupData.donutData.dataset.map((item, index) => {
                data.push(item.value)
                labels.push(item.label)
                colorArray.push(item.color)
            })

            setDataDonut({
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: colorArray,
                        font: {
                            family: 'AlmoniDL',
                            size: 20,
                        }
                    }
                ]
            })
        
            setDonutOptions({
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: 'כותרת',
                        font: {
                            size: 24,
                            family: 'AlmoniDL'
                        }
                    },
                    tooltip: {
                        font: {
                        },
                        callbacks: {
                            label: function (context) {
                                let label = context.label + ' ' + context.parsed;
        
                                if (context.label === 'קבוצות') {
                                    return [label, '(456) 5 קבוצות'];
                                }
                                else return label
                            }
        
                        }
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display: false,
                        borderRadius: 3,
                        font: {
                            size: 20,
                            family: 'AlmoniDL'
                        }
                      },
                }
            })

            setShowPopup(popupData.donutData && popupData.donutData.dataset)

        }else{
            console.log('no graph data')
        }
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    useEffect(() => {
        getPopUpInfo()
    }, [props.open, props.popupData])

    return (
        <ThemeProvider theme={props.themeWithLocale}>

            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={he}
            >
                {showPopup && <Dialog
                    fullWidth={true}
                    maxWidth='lg'
                    open={props.open}
                    onClose={props.handleClose} >
                    <div className='dashboardDialog'>
                        <Box id='dashboard_header' sx={{ direction: 'ltr', background: '#fffff', borderBottom: 2, borderColor: '#f0f0f0', padding:'0 1em' }}>
                            <AppBar id='dashboard_appbar' position='static'  sx={{ background: 'none', width: 'initial', boxShadow: 'unset' }}>
                                <Grid container className='block' spacing={1}>
                                    <Grid item xs>
                                        <IconButton
                                            size='large'
                                            edge='start'
                                            aria-label='menu'
                                            onClick={props.handleClose}
                                        >
                                            <CloseIcon sx={{ fontSize: 30, borderBlockColor: '#e2e2e2', color: '#000000'}} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs>
                                        <select className='filterSelect' value={props.popupFilter} onChange={(e) => props.setPopupFilter(Number(e.target.value))}>
                                            {/* <option value="2">יום</option>
                                            <option value="3">שבוע</option>
                                            <option value="4">חודש</option>
                                            <option value="5">שנה</option> */}
                                            <option value="6">תחומי התנדבות</option>
                                            <option value="7">אוכלוסיות</option>
                                        </select>
                                    </Grid>
                                    <Grid item xs>
                                        <Box sx={{borderStyle: 'Solid',borderWidth: 'thin', borderColor: '#e2e2e2', fontFamily: 'AlmoniDL' }}>
                                                <DashboardDatePicker key='dashboard.dashboardDialog' active={false} theme={props.themeWithLocale} selectedDateRange={props.selectedDateRange} setSelectedDateRange={props.setSelectedDateRange}/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: 18, textAlign: 'right', margin: 1, color: 'black', fontFamily: 'AlmoniDL' }}>
                                            {props.selectedLabel.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item alignContent='start' sx={{ color: props.leadingColor, fontSize:35, fontFamily: 'AlmoniDL' }}>
                                        {props.selectedLabel.icon}
                                    </Grid>
                                </Grid>
                            </AppBar>
                        </Box>

                        <Box sx={{ margin: '2em 0', maxHeight: '700px', padding: '10px' }}>
                            {/* {store.popupLoader ? <Loader showLoader={true} color={props.genericBlue}/> : */}
                            {store.popupLoader ? <div className='popup_loader'><CircularProgress sx={{color: props.genericBlue}}/></div> :
                            props.selectedLabel.chart !== 3 ? optionsBar && dataBar && <Bar options={optionsBar} data={dataBar} /> :               
                            dataDonut && donutOptions && <span id='dashboardDonutGraph'>
                                <Grid container key='donutCharts' justifyContent='center'>
                                    <Grid item xs={6}>
                                        <Doughnut data={dataDonut} options={donutOptions} />
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        <Doughnut data={dataDonut} options={donutOptions} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Doughnut data={dataDonut} options={donutOptions} />
                                    </Grid> */}
                                </Grid>
                                <div className='dashboardDonutInfo'>{dataDonut.labels.map((label, index) => <span key={`donutColor.${index}`}><span className='dot' style={{backgroundColor: dataDonut.datasets[0].backgroundColor[index] }}></span>{label}&ensp;</span>)}</div>
                            </span>
                                
                            }
                        </Box>

                    </div>

                </Dialog>}
            </LocalizationProvider>
        </ThemeProvider>
    )
}