import React from 'react';
import { Grid } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import './DashboardLabelBlock.css'


export default function DashboardLabelBlock(props) {
    const leadingStyle = {
        color: `${props.leadingColor}`
   }
    return (

        <div className='instructor-reports__top-block'>
            <Grid item xs className='block'>
                <span className='icon-label'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
                        <g fill="red" fillRule="nonzero">
                            <path d="M3.273 0H.727A.727.727 0 0 0 0 .727v2.546C0 3.674.326 4 .727 4h2.546A.727.727 0 0 0 4 3.273V.727A.727.727 0 0 0 3.273 0zm0 3.273H.727V.727h2.546v2.546zM15.2 0H6.8c-.442 0-.8.326-.8.727v2.546c0 .401.358.727.8.727h8.4c.442 0 .8-.326.8-.727V.727C16 .326 15.642 0 15.2 0zm0 3.273H6.8V.727h8.4v2.546zM3.273 6H.727A.727.727 0 0 0 0 6.727v2.546c0 .401.326.727.727.727h2.546A.727.727 0 0 0 4 9.273V6.727A.727.727 0 0 0 3.273 6zm0 3.273H.727V6.727h2.546v2.546zM15.2 6H6.8c-.442 0-.8.326-.8.727v2.546c0 .401.358.727.8.727h8.4c.442 0 .8-.326.8-.727V6.727c0-.401-.358-.727-.8-.727zm0 3.273H6.8V6.727h8.4v2.546zM3.273 11H.727a.727.727 0 0 0-.727.727v2.546c0 .401.326.727.727.727h2.546A.727.727 0 0 0 4 14.273v-2.546A.727.727 0 0 0 3.273 11zm0 3.273H.727v-2.546h2.546v2.546zM15.2 11H6.8c-.442 0-.8.326-.8.727v2.546c0 .401.358.727.8.727h8.4c.442 0 .8-.326.8-.727v-2.546c0-.401-.358-.727-.8-.727zm0 3.273H6.8v-2.546h8.4v2.546z"/>
                        </g>
                    </svg>
                </span>
                <span className='label-text'>כמות הנרשמים בקמפוס שלי</span>
                <div className='label-number'>1,883</div>
            </Grid>
            <br/>
            <Grid item xs className='block'>
                <span className='icon-label'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                        <path d="M9 16c-.256 0-.503-.093-.696-.261a208.551 208.551 0 0 0-2.047-1.76l-.003-.002c-1.813-1.544-3.38-2.877-4.469-4.19C.567 8.316 0 6.925 0 5.404c0-1.478.507-2.841 1.428-3.84A4.842 4.842 0 0 1 5.028 0a4.53 4.53 0 0 1 2.829.975c.422.327.805.727 1.143 1.193.338-.466.72-.866 1.144-1.193A4.53 4.53 0 0 1 12.972 0c1.39 0 2.668.556 3.6 1.566C17.493 2.564 18 3.927 18 5.405c0 1.52-.567 2.913-1.785 4.381-1.09 1.314-2.656 2.647-4.469 4.19a206.44 206.44 0 0 0-2.05 1.763c-.193.168-.44.261-.696.261zM5.028 1.053A3.799 3.799 0 0 0 2.203 2.28c-.74.803-1.149 1.912-1.149 3.125 0 1.279.476 2.423 1.543 3.71 1.031 1.242 2.565 2.548 4.341 4.06l.003.003c.621.529 1.325 1.128 2.057 1.768.737-.641 1.442-1.241 2.064-1.77 1.776-1.513 3.31-2.819 4.341-4.062 1.067-1.286 1.543-2.43 1.543-3.71 0-1.212-.408-2.321-1.149-3.124a3.799 3.799 0 0 0-2.825-1.227c-.8 0-1.535.255-2.184.756-.578.446-.98 1.011-1.216 1.406A.66.66 0 0 1 9 3.54a.66.66 0 0 1-.572-.325c-.236-.395-.638-.96-1.216-1.406a3.499 3.499 0 0 0-2.184-.756z" fill="red" fillRule="nonzero"/>
                    </svg>    
                </span>
                <span className='label-text'>כמות הנרשמים בקמפוס שלי</span>
                <div className='label-number'>3</div>
            </Grid>


        </div>

    )

}